<template>
  <div>
    <input
      type="checkbox"
      id="share-modal"
      class="hidden modal-toggle"
      :checked="open"
    >
    <div

      class="modal"
    >
      <div
        class="modal-box"
        id="share-modal-focus"
        style="min-width: 500px;"
      >
        <div class="flex justify-between items-center">
          <h3 class="text-lg font-semibold mb-4">
            Share report
          </h3>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            @click="$emit('close')"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-14 h-14 cursor-pointer"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <p>
          Share report with the candidate (email)
        </p>
        <div class="form-control w-full mt-3">
          <div class="mb-4 flex">
            <input
              :value="candidate.email"
              data-theme="mytheme"
              type="email"
              disabled="true"
              placeholder="Enter email"
              class="shadow disabled appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
            <button
              @click="submitShare"
              class="btn-primary ml-2"
            >
              Share
            </button>
          </div>
          <div
            v-if="sharedSuccess"
            class="mt-5 p-8 mb-4 text-sm text-green-700 bg-green-100 rounded-lg "
          >
            Report shared with {{ candidate.email }}
          </div>
        </div>
        <p>Generate a sharable link (valid 1 week)</p>

        <div
          v-if="!link"
          class="form-control w-full mt-3"
        >
          <div class="mb-4 flex">
            <button
              @click="generateSharableLink"
              class="btn-primary ml-2"
            >
              Generate
            </button>
          </div>
        </div>

        <div
          v-else
          class="form-control w-full mt-3"
        >
          <div class="mb-4 flex">
            <input
              @focus="$event.target.select()"
              ref="linkInput"
              :value="link"
              readonly
              data-theme="mytheme"
              type="email"
              placeholder="Enter email"
              class="shadow disabled appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
            <button
              class="btn-primary flex"
              @click="copy"
            >
              Copy
            </button>
          </div>
          <div
            v-if="copiedMessage"
            class="mt-5 p-8 mb-4 text-sm text-green-700 bg-green-100 rounded-lg "
          >
            Link copied
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    candidate: {
      type: Object,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      clipboard: null,
      copiedMessage: false,
      sharedSuccess: false,
      link: null
    }
  },
  methods: {
    async submitShare () {
      await this.$api.shareReport(this.candidate.email, 'full_cognitive_report', this.candidate.user_id, this.campaignId)
      this.sharedSuccess = true

      setTimeout(() => {
        this.sharedSuccess = false
      }, 1500)
    },
    async generateSharableLink () {
      const { url } = await this.$api.generateSharableLink('full_cognitive_report', this.candidate.user_id, this.campaignId)

      this.link = url
    },
    copy () {
      this.$refs.linkInput.focus()
      document.execCommand('copy')
      this.copiedMessage = true
      setTimeout(() => {
        this.copiedMessage = false
      }, 1500)
    }
  }
}

</script>
