<template>
  <div class="flex">
    <div
      class="grid gap-x-2"
      :class="{'grid-flow-col': propname != 'Strength', 'grid-cols-1': propname === 'Strength'}"
    >
      <div
        v-for="(entry) in selectedKeys"
        :key="entry[0]"
      >
        <div
          class="badge"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="inline-block w-4 h-4 cursor-pointer stroke-current "
            @click="value[entry[0]] = false"
          ><path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          /></svg>
          <span v-if="entry[0] in translations">{{ translations[entry[0]] }}</span><span v-else>{{ entry[0] }}</span>
        </div>
      </div>
    </div>
    <div class="flex ml-auto">
      <div class="dropdown dropdown-left">
        <div
          tabindex="0"
          class="flex p-2 border-2 rounded-md cursor-pointer "
        >
          {{ propname }} <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
        <ul
          tabindex="0"
          class="p-2 shadow dropdown-content bg-base-100 rounded-box "
          :class="{'w-72': propname != 'Strength', 'customWidth': propname === 'Strength'}"
        >
          <li
            v-for="(val, name) in value"
            :key="name"
            class="flex items-center mt-1"
          >
            <input
              type="checkbox"
              v-model="value[name]"
              class="mr-2 checkbox checkbox-md"
            > <span v-if="name in translations">{{ translations[name] }}</span><span v-else>{{ name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    propname: {
      type: String,
      required: true
    }
  },
  computed: {
    selectedKeys () {
      return Object.entries(this.value).filter(el => el[1] === true)
    }
  },
  data () {
    return {
      translations: {
        working_memory: 'Working Memory',
        simple_attention: 'Simple attention',
        strategic_thinking: 'Strategic Thinking',
        short_term_memory: 'Short-term Memory',
        impulse_control: 'Impulse Control',
        creativity_cognitive_flexibility: 'Creativity & Cognitive Flexibility',
        conceptualization: 'Conceptualization',
        attention_control: 'Attention Control'
      }
    }
  }
}
</script>

<style scoped>

.customWidth {
  width: 320px;
}

</style>
