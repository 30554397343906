<template>
  <div
    class="flex justify-center items-center"
    v-if="!main"
  >
    <svg
      v-if="!value"
      class="h-7 w-7 cursor-pointer"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      @click="handleInput"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
      />
    </svg>
    <svg
      v-else
      class="h-7 w-7 cursor-pointer"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      @click="handleInput"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
      />
    </svg>
  </div>
  <div
    v-else
    @click="handleInput"
    class="flex"
  >
    <svg
      v-if="!value"
      class="h-6 w-6 cursor-pointer"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      @click="handleInput"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
      />
    </svg>
    <svg
      v-else
      class="h-6 w-6 cursor-pointer"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
      />
    </svg>

    <label
      v-if="isSubReportCandidateDetails"
      class="ml-3 font-normal underline text-base mouse-hover"
    >
      Candidate details</label>

    <label
      v-if="isSubReportTopDescription"
      class="ml-3 font-normal underline text-base mouse-hover"
    >
      Full description</label>

    <label
      v-if="isSubReportDescription"
      class="ml-3 font-normal underline text-base mouse-hover"
    >
      Description</label>

    <label
      v-if="isSubReportSensitivity"
      class="ml-3 font-normal underline text-base mouse-hover"
    >
      State details</label>

    <label
      v-if="!isSubReportCandidateDetails && !isSubReportTopDescription && !isSubReportDescription && !isSubReportSensitivity"
      class="ml-3 font-normal underline text-base mouse-hover"
    >
      {{ value !== true ? 'Show ': 'Hide ' }}candidate details</label>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: {
      type: String,
      required: false,
      default: null
    },
    main: {
      type: Boolean,
      required: false,
      default: false
    },
    isSubReportTopDescription: { type: Boolean, default: false },
    isSubReportDescription: { type: Boolean, default: false },
    isSubReportCandidateDetails: { type: Boolean, default: false },
    isSubReportSensitivity: { type: Boolean, default: false }
  },
  methods: {
    handleInput () {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style>

</style>
