<template>
  <div @click="applySorting">
    <div
      :class="{'focus-up': isSorting && !value.invert }"
      class="arrow-up mb-1"
    />
    <div
      :class="{'focus-down': isSorting && value.invert }"
      class="arrow-down"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    variable: {
      type: String,
      required: true
    }
  },
  computed: {
    isSorting () {
      return this.variable === this.value.variable
    }
  },

  methods: {
    applySorting () {
      if (this.isSorting) {
        this.value.invert = !this.value.invert
        this.$emit('input', this.value)
      } else {
        this.$emit('input', { variable: this.variable, invert: false })
      }
    }
  }
}
</script>

<style scoped>
.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 5px solid;

}

.focus-down {
  border-top: 5px solid gray;
}
.focus-up {
  border-bottom: 5px solid gray;
}
.arrow-up{
  color: white;
}
.arrow-down{
  color: white;
}
</style>
