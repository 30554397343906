<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 377.021 377.021"
    stroke="currentColor"
    class="h-8 w-8 text-center"
    style="transform: rotate(90deg);"
  >
    <g>
      <path
        d="M279.522,36.163h-22.087V20c0-11.028-8.972-20-20-20h-97.85c-11.028,0-20,8.972-20,20v16.163H97.499 c-16.542,0-30,13.458-30,30v280.858c0,16.542,13.458,30,30,30h182.023c16.542,0,30-13.458,30-30V66.163 C309.522,49.621,296.064,36.163,279.522,36.163z M97.499,347.021V66.163h182.023l0.003,280.858H97.499z"
      />
      <rect
        v-if="capacityLevel >=1"
        x="122.586"
        y="285"
        width="131.85"
        height="55"
      />
      <rect
        v-if="capacityLevel >=2"
        x="122.586"
        y="215"
        width="131.85"
        height="55"
      />
      <rect
        v-if="capacityLevel >=3"
        x="122.586"
        y="145"
        width="131.85"
        height="55"
      />
      <rect
        v-if="capacityLevel >= 4"
        x="122.586"
        y="75"
        width="131.85"
        height="55"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    capacityLevel: {
      type: Number,
      required: true
    }
  }

}
</script>

<style>

</style>
