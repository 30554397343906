<template>
  <div class="modal filter-modal">
    <div class="modal-box p-8">
      <div>
        <h3 class="mb-4">
          Filters
        </h3>
        <p>Set your own requirement score</p>
        <div class="divider py-4" />
      </div>

      <div class="w-full">
        <div class="flex justify-between items-center mt-5">
          <h5>Gender</h5>
          <filter-dropdown
            propname="Gender"
            v-model="value.gender"
          />
        </div>
        <div class="flex justify-between items-center mt-5">
          <h5>State</h5>
          <filter-dropdown
            propname="State"
            v-model="value.state"
          />
        </div>
        <div class="flex justify-between items-center mt-5">
          <h5>Strength</h5>
          <filter-dropdown
            propname="Strength"
            v-model="value.strength"
          />
        </div>
        <div class="flex justify-between items-center mt-5">
          <h5>Awareness</h5>
          <filter-dropdown
            propname="Awareness"
            v-model="value.awareness"
          />
        </div>

        <div class="flex justify-between items-center mt-5">
          <h5>
            Capacity
          </h5>
          <div class="flex">
            <div
              class="grid gap-x-2 grid-flow-col"
            >
              <div v-if="value.capacity_level">
                <div
                  class="badge badge-lg py-4 bg-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    class="inline-block w-4 h-4 stroke-current text-black cursor-pointer "
                    @click="value.capacity_level = null"
                  ><path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  /></svg>
                  <capacity-icon
                    class="text-white"
                    :capacity-level="value.capacity_level"
                  />
                </div>
              </div>
            </div>
            <div class="ml-auto dropdown dropdown-left">
              <div
                tabindex="0"
                class=" flex cursor-pointer border-2 p-2 rounded-md"
              >
                Capacity <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <ul
                tabindex="0"
                class="dropdown-content p-2 shadow bg-base-100 rounded-box"
              >
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.capacity_level"
                    value="1"
                    type="radio"
                    class="radio radio-md mr-2"
                  >≥<capacity-icon :capacity-level="1" />
                </li>
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.capacity_level"
                    value="2"
                    type="radio"
                    class="radio radio-md mr-2"
                  >≥<capacity-icon :capacity-level="2" />
                </li>
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.capacity_level"
                    value="3"
                    type="radio"
                    class="radio radio-md mr-2"
                  >≥<capacity-icon :capacity-level="3" />
                </li>
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.capacity_level"
                    value="4"
                    type="radio"
                    class="radio radio-md mr-2"
                  >≥<capacity-icon :capacity-level="4" />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex justify-between items-center mt-5">
          <h5>
            Handling pressure
          </h5>
          <div class="flex">
            <div
              class="grid gap-x-2 grid-flow-col"
            >
              <div v-if="value.variation">
                <div
                  class="badge badge-lg py-4 bg-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    class="inline-block w-4 h-4 stroke-current text-black cursor-pointer "
                    @click="value.variation = null"
                  ><path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  /></svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-black"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    :transform="`rotate(${180-(value.variation-1)*45})`"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="ml-auto dropdown dropdown-left">
              <div
                tabindex="0"
                class=" flex cursor-pointer border-2 p-2 rounded-md"
              >
                Handling pressure  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <ul
                tabindex="0"
                class="dropdown-content p-2 shadow bg-base-100 rounded-box"
              >
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.variation"
                    :value="1"
                    type="radio"
                    class="radio radio-md mr-2"
                  >≥ <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    :transform="`rotate(${180-(1-1)*45})`"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </li>
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.variation"
                    value="2"
                    type="radio"
                    class="radio radio-md mr-2"
                  >≥ <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    :transform="`rotate(${180-(2-1)*45})`"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </li>
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.variation"
                    value="3"
                    type="radio"
                    class="radio radio-md mr-2"
                  >≥ <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    :transform="`rotate(${180-(3-1)*45})`"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </li>
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.variation"
                    value="4"
                    type="radio"
                    class="radio radio-md mr-2"
                  > ≥ <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    :transform="`rotate(${180-(4-1)*45})`"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </li>
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.variation"
                    value="5"
                    type="radio"
                    class="radio radio-md mr-2"
                  >≥  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    :transform="`rotate(${180-(5-1)*45})`"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center mt-5 hidden">
          <h5>
            Endurance
          </h5>
          <div class="flex">
            <div
              class="grid gap-x-2 grid-flow-col"
            >
              <div v-if="value.endurance">
                <div
                  class="badge badge-lg py-4 bg-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    class="inline-block w-4 h-4 stroke-current text-black cursor-pointer "
                    @click="value.endurance = null"
                  ><path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  /></svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-black"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    :transform="`rotate(${180-(value.endurance-1)*45})`"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="ml-auto dropdown dropdown-left">
              <div
                tabindex="0"
                class=" flex cursor-pointer border-2 p-2 rounded-md"
              >
                Endurance <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <ul
                tabindex="0"
                class="dropdown-content p-2 shadow bg-base-100 rounded-box"
              >
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.endurance"
                    :value="1"
                    type="radio"
                    class="radio radio-md mr-2"
                  >≥ <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    :transform="`rotate(${180-(1-1)*45})`"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </li>
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.endurance"
                    value="2"
                    type="radio"
                    class="radio radio-md mr-2"
                  >≥ <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    :transform="`rotate(${180-(2-1)*45})`"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </li>
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.endurance"
                    value="3"
                    type="radio"
                    class="radio radio-md mr-2"
                  >≥ <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    :transform="`rotate(${180-(3-1)*45})`"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </li>
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.endurance"
                    value="4"
                    type="radio"
                    class="radio radio-md mr-2"
                  > ≥ <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    :transform="`rotate(${180-(4-1)*45})`"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </li>
                <li
                  class="flex items-center mt-1"
                >
                  <input
                    v-model="value.endurance"
                    value="5"
                    type="radio"
                    class="radio radio-md mr-2"
                  >≥  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    :transform="`rotate(${180-(5-1)*45})`"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 10l7-7m0 0l7 7m-7-7v18"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- team role -->
        <div class="flex justify-between items-center mt-5">
          <h5>
            Team Role
          </h5>
          <div class="flex">
            <div class="ml-auto dropdown dropdown-left">
              <div
                tabindex="0"
                class=" flex cursor-pointer border-2 p-2 rounded-md"
              >
                Team Role  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <ul
                tabindex="0"
                class="dropdown-content p-2 shadow bg-base-100 rounded-box"
              >
                <li
                  v-for="role in filterOptions.team_roles"
                  :key="role"
                  class="flex items-center mt-1 whitespace-nowrap"
                >
                  <input
                    v-model="value[role]"
                    :value="role"
                    type="checkbox"
                    class="checkbox checkbox-md mr-2"
                    @change="handleInput('teamRole', role)"
                  >
                  {{ role }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Division -->
        <div class="flex justify-between items-center mt-5">
          <h5>
            Business Area
          </h5>
          <div class="flex">
            <!-- <div
              class="grid gap-x-2 grid-flow-col"
            >
              <div v-if="filterOptions.team_roles">
                <div
                  class="badge badge-lg py-4 bg-white"
                />
              </div>
            </div> -->

            <div class="ml-auto dropdown dropdown-left">
              <div
                tabindex="0"
                class=" flex cursor-pointer border-2 p-2 rounded-md"
              >
                Business Area  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <ul
                tabindex="0"
                class="dropdown-content p-2 shadow bg-base-100 rounded-box"
              >
                <li
                  v-for="division in filterOptions.divisions"
                  :key="division"
                  class="flex items-center mt-1 whitespace-nowrap"
                >
                  <input
                    v-model="value[division]"
                    :value="division"
                    type="checkbox"
                    class="checkbox checkbox-md mr-2"
                    @change="handleInput('division', division)"
                  >
                  {{ division }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Department -->
        <div class="flex justify-between items-center mt-5">
          <h5>
            Department
          </h5>
          <div class="flex">
            <!-- <div
              class="grid gap-x-2 grid-flow-col"
            >
              <div v-if="filterOptions.team_roles">
                <div
                  class="badge badge-lg py-4 bg-white"
                />
              </div>
            </div> -->

            <div class="ml-auto dropdown dropdown-left">
              <div
                tabindex="0"
                class=" flex cursor-pointer border-2 p-2 rounded-md"
              >
                Department  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <ul
                tabindex="0"
                class="dropdown-content p-2 shadow bg-base-100 rounded-box"
              >
                <li
                  v-for="department in filterOptions.departments"
                  :key="department"
                  class="flex items-center mt-1 whitespace-nowrap"
                >
                  <input
                    v-model="value[department]"
                    :value="department"
                    type="checkbox"
                    class="checkbox checkbox-md mr-2"
                    @change="handleInput('department', department)"
                  >
                  {{ department }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Company -->
        <div class="flex justify-between items-center mt-5">
          <h5>
            Company
          </h5>
          <div class="flex">
            <!-- <div
              class="grid gap-x-2 grid-flow-col"
            >
              <div v-if="filterOptions.team_roles">
                <div
                  class="badge badge-lg py-4 bg-white"
                />
              </div>
            </div> -->

            <div class="ml-auto dropdown dropdown-left">
              <div
                tabindex="0"
                class=" flex cursor-pointer border-2 p-2 rounded-md"
              >
                Company  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <ul
                tabindex="0"
                class="dropdown-content p-2 shadow bg-base-100 rounded-box"
              >
                <li
                  v-for="company in filterOptions.companies"
                  :key="company"
                  class="flex items-center mt-1 whitespace-nowrap"
                >
                  <input
                    v-model="value[company]"
                    :value="company"
                    type="checkbox"
                    class="checkbox checkbox-md mr-2"
                    @change="handleInput('company', company)"
                  >
                  {{ company }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Position -->
        <div class="flex justify-between items-center mt-5">
          <h5>
            Position
          </h5>
          <div class="flex">
            <!-- <div
              class="grid gap-x-2 grid-flow-col"
            >
              <div v-if="filterOptions.team_roles">
                <div
                  class="badge badge-lg py-4 bg-white"
                />
              </div>
            </div> -->

            <div class="ml-auto dropdown dropdown-left">
              <div
                tabindex="0"
                class=" flex cursor-pointer border-2 p-2 rounded-md"
              >
                Position  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <ul
                tabindex="0"
                class="dropdown-content p-2 shadow bg-base-100 rounded-box"
              >
                <li
                  v-for="position in filterOptions.positions"
                  :key="position"
                  class="flex items-center mt-1 whitespace-nowrap"
                >
                  <input
                    v-model="value[position]"
                    :value="position"
                    type="checkbox"
                    class="checkbox checkbox-md mr-2"
                    @change="handleInput('position', position)"
                  >
                  {{ position }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-action">
        <label
          for="my-modal-2"
          class="btn-primary mt-8"
          @click="$emit('applyFiltering')"
        >OK</label>
      </div>
    </div>
  </div>
</template>

<script>
import FilterDropdown from './FilterDropdown.vue'
import CapacityIcon from './CapacityIcon.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    FilterDropdown,
    CapacityIcon
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({ filterOptions: 'TEAMSCOREBOARD/getFilterOptions' })
  },
  methods: {
    handleInput (key, value) {
      const updatedFilters = { ...this.value }

      console.log(updatedFilters)

      console.log(updatedFilters[key])

      if (!updatedFilters[key]) {
        updatedFilters[key] = [value]
      } else {
        const index = updatedFilters[key].indexOf(value)
        if (index === -1) {
          updatedFilters[key].push(value)
        } else {
          updatedFilters[key].splice(index, 1)
          if (updatedFilters[key].length === 0) {
            updatedFilters[key] = null
          }
        }
      }

      this.$emit('input', updatedFilters)
    }
  }
}
</script>
