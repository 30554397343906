<template>
  <div class="flex flex-col">
    <table class="flex-auto table table-compact">
      <thead>
        <tr>
          <th class="scoreboard-table-hearer flex">
            <div class="flex items-center pb-1">
              <div class="w-12" />
              <div class="text-white normal-case text-base font-normal">
                State Tendency
              </div>
            </div>
          </th>
          <th class="scoreboard-table-hearer">
            <div class="flex space-x-4 justify-start">
              <span class="text-white normal-case text-base font-normal">Common Strength</span>
            </div>
          </th>
          <th class="scoreboard-table-hearer">
            <div class="flex space-x-4 justify-start">
              <span class="text-white normal-case text-base font-normal">Common Weakness</span>
            </div>
          </th>
          <th class="scoreboard-table-hearer">
            <div class="flex space-x-4 justify-center">
              <span class="text-white normal-case text-base font-normal">Average Capacity</span>
            </div>
          </th>
          <th class="scoreboard-table-hearer">
            <div class="flex space-x-4 justify-start">
              <span class="text-white normal-case text-base font-normal">Self-Awareness Tendency</span>
            </div>
          </th>
          <th class="scoreboard-table-hearer">
            <div class="flex space-x-4 justify-center">
              <span class="text-white normal-case text-base font-normal">Pressure Handling Tendency</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="border-2">
          <td>
            <div class="flex items-center justify-center -mt-4">
              <span
                class="text-2xl line-height-7rem"
                :style="'color: ' + content.colorTranslations[this.$store.state.TEAMSCOREBOARD.summaryData.state_median]"
              >⬤</span>
            </div>
          </td>
          <td>
            <div class="flex items-center justify-start ml-2">
              <div class="text-base">
                {{ getLabel(this.$store.state.TEAMSCOREBOARD.summaryData.strength_max) }}
              </div>
            </div>
          </td>
          <td>
            <div class="flex items-center justify-start ml-2">
              <div class="text-base">
                {{ getLabel(this.$store.state.TEAMSCOREBOARD.summaryData.weakness_min) }}
              </div>
            </div>
          </td>
          <td>
            <div class="flex items-center justify-center ml-4">
              <div class="text-base">
                {{ this.$store.state.TEAMSCOREBOARD.summaryData.capacity_avg }}
              </div>
            </div>
          </td>
          <td>
            <div class="flex items-center justify-start ml-2">
              <div class="text-base">
                {{ content.awarenessTranslations[this.$store.state.TEAMSCOREBOARD.summaryData.awareness_avg] }}
              </div>
            </div>
          </td>
          <td>
            <div class="flex items-center justify-center ml-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-16 w-full"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="1.5"
                :transform="`rotate(${180 - (this.$store.state.TEAMSCOREBOARD.summaryData.variation_avg - 1) * 45})`"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5 10l7-7m0 0l7 7m-7-7v18"
                />
              </svg>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import { Scoreboard, Charts } from '@/content/reportContent.json'

export default {
  props: {
    campaignId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      content: Scoreboard
    }
  },
  methods: {
    stateColorChange (state, testIssue) {
      if (testIssue !== null && state >= 3) {
        return 4
      } else {
        return state
      }
    },
    getLabel (strengthKey) {
      return strengthKey ? Charts.MainMeasures[strengthKey].label : ''
    }
  }
}
</script>

<style scoped>
.overview-box {
  min-height: 13rem;
  max-width: 80%;
  border-radius: 6px;
}

.overview-box > * {
  padding: 1.5rem;
  justify-content: space-between;
  align-items: center;
  width: calc(100% / 6);

}
.overview-title {
  font-size: 1.5rem;
}

.overview-result {
  font-size: 14px;
}
</style>
