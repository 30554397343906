<template>
  <div
    class="dropdown dropdown-hover"
  >
    <div
      tabindex="0"
    >
      <div><slot /></div>
    </div>
    <div
      tabindex="0"
      class="shadow dropdown-content bg-white font-normal normal-case leading-normal text-base rounded-box w-56 p-4"
      style="white-space: normal;"
    >
      <p>{{ desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    desc: {
      type: String,
      required: true
    }
  }
}
</script>

<style>

</style>
