<template>
  <div>
    <div class="flex justify-between pb-8 items-start mb-12">
      <team-overview
        :campaign-id="campaignId"
      />
      <div
        class="dropdown dropdown-top dropdown-end px-2"
      >
        <label
          tabindex="0"
          class="cursor-pointer"
        >
          <button class="btn-primary-dropdown flex justify-center items-center">
            Team Reports<svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 ml-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
        </label>
        <ul
          tabindex="0"
          class="p-4 shadow dropdown-content menu bg-base-100 rounded-box w-96 border border-gray-300 text-black"
        >
          <li
            :class="['text-sm', 'text-left', 'leading-snug', { 'opacity-50 cursor-not-allowed pointer-events-none': !isTested }]"
          >
            <router-link
              :to="{ name: 'TeamReport', params: { campaignId: this.campaignId } }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"
                />
              </svg>
              Team Cognitive Spider
            </router-link>
          </li>
          <li
            @click="redirectToTeamReport"
            class="text-sm text-left leading-snug"
          >
            <a><div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="mr-2 w-7 h-7"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                />
              </svg>
            </div>
              Full Team Report</a>
          </li>
        </ul>
      </div>
    </div>
    <h5 class="font-medium mb-8">
      Scoreboard Individuals
    </h5>
    <div class="flex items-end justify-between">
      <div class="flex items-end custom-tab">
        <button
          @click="filters = { ...filters, favoritesOnly: false }; applySorting()"
          class="unClick-mode mx-2 px-8"
          :class="{ 'click-mode': !filters.favoritesOnly }"
        >
          All
        </button>
        <button
          @click="filters = { ...filters, favoritesOnly: true }; applySorting()"
          class="unClick-mode mx-2 px-8"
          :class="{ 'click-mode': filters.favoritesOnly }"
        >
          Favorites
        </button>
      </div>
      <div class="flex items-center">
        <div class="flex mr-10 h-9">
          <div class="px-6 flex flex-row items-center border-left-tp-nav">
            <show-details-btn
              v-model="showCandidateInfo"
              title="candidate details"
              :main="true"
            />
          </div>
          <div class="px-6 flex flex-row items-center border-left-tp-nav">
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.66667 0C8.118 0 9.35267 0.954023 9.81033 2.28562H18.8889C19.5026 2.28562 20 2.79729 20 3.42848C20 4.05966 19.5026 4.57134 18.8889 4.57134H9.81033C9.35278 5.90309 8.118 6.85714 6.66667 6.85714C5.21527 6.85714 3.98053 5.90309 3.52297 4.57134H1.11111C0.497467 4.57134 0 4.05966 0 3.42848C0 2.79729 0.497467 2.28562 1.11111 2.28562H3.52303C3.98066 0.954023 5.21534 0 6.66667 0ZM7.77778 3.42857C7.77778 2.79739 7.28031 2.28571 6.66667 2.28571C6.05301 2.28571 5.55556 2.79739 5.55556 3.42857C5.55556 4.05976 6.05301 4.57143 6.66667 4.57143C7.28031 4.57143 7.77778 4.05976 7.77778 3.42857Z"
                fill="#25213B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.3333 9.14286C14.7847 9.14286 16.0193 10.0969 16.477 11.4285H18.8889C19.5026 11.4285 20 11.9401 20 12.5713C20 13.2025 19.5026 13.7142 18.8889 13.7142H16.477C16.0194 15.0459 14.7847 16 13.3333 16C11.8819 16 10.6472 15.0459 10.1897 13.7142H1.11113C0.497488 13.7142 2.11928e-05 13.2025 2.11928e-05 12.5713C2.11928e-05 11.9401 0.497488 11.4285 1.11113 11.4285H10.1897C10.6473 10.0969 11.882 9.14286 13.3333 9.14286ZM14.4444 12.5714C14.4444 11.9402 13.947 11.4286 13.3333 11.4286C12.7197 11.4286 12.2222 11.9402 12.2222 12.5714C12.2222 13.2026 12.7197 13.7143 13.3333 13.7143C13.947 13.7143 14.4444 13.2026 14.4444 12.5714Z"
                fill="#25213B"
              />
            </svg>
            <label
              for="my-modal-2"
              class="ml-3 font-normal modal-button underline text-base cursor-pointer"
            >
              Filters</label>
            <input
              id="my-modal-2"
              type="checkbox"
              class="modal-toggle"
            >
            <filter-modal
              v-model="filters"
              @applyFiltering="applySorting()"
            />
          </div>
        </div>
        <div class="pr-2">
          <select
            @change="e => { filters = { ...filters, requirementSpecificationId: e.target.value }; applySorting() }"
            :value="filters.requirementSpecificationId"
            class="form-select border px-8 py-4 select-campaign-dropdown text-base"
          >
            <option value="NO_REQ_SPEC">
              No requirement specification
            </option>
            <option disabled>
              ---
            </option>
            <option
              v-for="requirementSpecification in requirementSpecifications"
              :value="requirementSpecification.id"
              :key="requirementSpecification.id"
            >
              {{ requirementSpecification.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <hr class="h-2 mt-6 mb-10">
    <div class="flex flex-col">
      <table class="flex-auto table table-compact">
        <thead>
          <tr>
            <th class="scoreboard-table-hearer flex">
              <div class="flex items-center pb-1">
                <div class="w-12" />
                <div class="text-white normal-case text-base font-normal">
                  Name
                </div>
              </div>
            </th>
            <th class="scoreboard-table-hearer" />
            <th class="scoreboard-table-hearer">
              <div class="flex items-center space-x-4">
                <dropdown-table-header desc="Full circle means full test performed.">
                  <span class="text-white normal-case text-base font-normal">Candidate Summary</span>
                </dropdown-table-header>
              </div>
            </th>
            <th class="scoreboard-table-hearer">
              <div class="flex items-center space-x-4">
                <dropdown-table-header desc="Full circle means full test performed.">
                  <span class="text-white normal-case text-base font-normal">Test performed</span>
                </dropdown-table-header>
              </div>
            </th>
            <th class="scoreboard-table-hearer">
              <div class="flex space-x-4">
                <dropdown-table-header desc="The state indicated by the individual at the time of testing.">
                  <span class="text-white normal-case text-base font-normal">State </span>
                </dropdown-table-header>
                <div>
                  <sort-icon
                    v-model="sorting"
                    variable="state"
                  />
                </div>
              </div>
            </th>
            <th class="scoreboard-table-hearer">
              <div class="flex space-x-4">
                <dropdown-table-header desc="Strongest cognitive ability at the time of testing.">
                  <span class="text-white normal-case text-base font-normal">Strength </span>
                </dropdown-table-header>
                <div>
                  <sort-icon
                    v-model="sorting"
                    variable="strength"
                  />
                </div>
              </div>
            </th>
            <th class="scoreboard-table-hearer">
              <div class="flex space-x-4">
                <dropdown-table-header desc="Total average capacity score.">
                  <span class="text-white normal-case text-base font-normal">Total capacity </span>
                </dropdown-table-header>
                <div>
                  <sort-icon
                    v-model="sorting"
                    variable="total_capacity_score"
                  />
                </div>
              </div>
            </th>
            <th class="scoreboard-table-hearer">
              <div class="flex space-x-4">
                <dropdown-table-header desc="Tendency in estimation of own capacity. See details in the full report.">
                  <span class="text-white normal-case text-base font-normal">Self-Awareness </span>
                </dropdown-table-header>
                <div>
                  <sort-icon
                    v-model="sorting"
                    variable="awareness"
                  />
                </div>
              </div>
            </th>
            <th class="scoreboard-table-hearer">
              <div class="flex space-x-4 ">
                <dropdown-table-header desc="Tendency in performance under pressure.">
                  <span class="text-white normal-case text-base font-normal">Handling pressure </span>
                </dropdown-table-header>
                <div>
                  <sort-icon
                    v-model="sorting"
                    variable="variation"
                  />
                </div>
              </div>
            </th>
            <th
              class="scoreboard-table-hearer"
              v-if="filters.requirementSpecificationId !== 'NO_REQ_SPEC'"
            >
              <div class="flex space-x-4 ">
                <dropdown-table-header desc="How well the candidate match your requirements">
                  <span class="text-white normal-case text-base font-normal"> Match </span>
                </dropdown-table-header>
                <div>
                  <sort-icon
                    v-model="sorting"
                    variable="matching"
                  />
                </div>
              </div>
            </th>
            <th class="scoreboard-table-hearer">
              <div class="flex space-x-4 justify-center">
                <span class="text-white normal-case text-base font-normal">Reports </span>
              </div>
            </th>
            <th class="scoreboard-table-hearer">
              <div class="flex space-x-4 justify-center">
                <span class="text-white normal-case text-base font-normal">Share </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showLoading">
            <td />
            <td />
            <td />
            <td>
              <div class="flex items-center justify-center w-full h-full">
                <button
                  v-if="showLoading"
                  class="btn btn-lg btn-circle btn-ghost loading"
                />
                <span
                  v-if="!showLoading"
                  class="text-base"
                >
                  No candidates
                </span>
              </div>
            </td>
          </tr>
          <template v-else>
            <table-row
              v-for="candidate in candidates"
              :key="candidate.id"
              :candidate="candidate"
              :show-info="showInfo"
              :show-candidate-info="showCandidateInfo"
              :sorting="sorting"
              :campaign-id="campaignId"
              :has-done-report="candidate.tested != null"
              @openReport="reportModalData = $event"
              @share="shareModalData = $event"
            />
          </template>
        </tbody>
      </table>
    </div>
    <confirm-report-modal
      :report-type="reportModalData.reportType"
      @close="reportModalData = null"
      v-if="reportModalData"
      :candidate="reportModalData.candidate"
      :campaign-id="campaignId"
      :label="reportModalData.label"
      :show-info="showInfo"
    />
    <share-modal
      v-if="shareModalData"
      :open="!!shareModalData"
      :candidate="shareModalData.candidate"
      @close="shareModalData = null"
      :campaign-id="this.campaignId"
    />
  </div>
</template>

<script>
import TableRow from '../components/TableRow.vue'
import DropdownTableHeader from '../components/DropdownTableHeader.vue'
import SortIcon from '../components/SortIcon.vue'
import ShowDetailsBtn from '../components/ShowDetailsBtn.vue'
import FilterModal from '../components/FilterModal.vue'
import { mapGetters } from 'vuex'
import ConfirmReportModal from '../components/ConfirmReportModal.vue'
import ShareModal from '@/modules/AppReport/components/ShareModal.vue'
import TeamOverview from '../components/TeamOverview.vue'

import download from 'downloadjs'
import axios from 'axios'

export default {
  components: {
    TableRow,
    DropdownTableHeader,
    SortIcon,
    ShowDetailsBtn,
    FilterModal,
    ConfirmReportModal,
    ShareModal,
    TeamOverview
  },
  props: {
    campaignId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      gettingCandidates: false,
      showCandidateInfo: false,
      showConfirmReportModal: false,
      showInfo: true,
      reportModalData: null,
      shareModalData: null
    }
  },
  computed: {
    ...mapGetters({
      candidates: 'TEAMSCOREBOARD/getCandidates',
      issues: 'TEAMSCOREBOARD/getCandidatesTestIssues',
      currentPages: 'TEAMSCOREBOARD/getPages',
      requirementSpecifications: 'REQUIREMENT_SPECIFICATIONS/getRequirementSpecifications'

    }),
    filters: {
      get () {
        return this.$store.state.TEAMSCOREBOARD.filters
      },
      set (value) {
        this.$store.dispatch('TEAMSCOREBOARD/updateFilters', value)
      }
    },
    sorting: {
      get () {
        return this.$store.state.TEAMSCOREBOARD.sorting
      },
      set (value) {
        this.$store.dispatch('TEAMSCOREBOARD/updateSorting', value)
      }
    },
    showLoading () {
      return this.gettingCandidates
    },
    isTested () {
      // Loop through candidates array and check if at least one has completed the test
      return this.candidates.some(candidate => candidate.tested)
    }
  },

  watch: {
    sorting: {
      async handler (newSort, oldSort) {
        await this.applySorting()
      },
      deep: true
    }
  },

  async mounted () {
    await this.$store.dispatch('USER/updateSubscriptionStatus')
    await this.$store.dispatch('REQUIREMENT_SPECIFICATIONS/getRequirementSpecifications')

    const id = this.campaignId
    this.gettingCandidates = true
    await this.$store.dispatch('TEAMSCOREBOARD/getCandidates', id)
    await this.$store.dispatch('TEAMSCOREBOARD/getCandidatesTestIssues', id)
    this.gettingCandidates = false
    this.mapIssuesAndUsers()

    if (this.candidates && Array.isArray(this.candidates)) {
      for (const candidate of this.candidates) {
        if (candidate.stages && Symbol.iterator in Object(candidate.stages)) {
          for (const stage of candidate.stages) {
            if (stage !== 'full_cognitive_report') {
              this.showInfo = false
            }
          }
        }
      }
    }
  },

  methods: {
    async applySorting () {
      this.gettingCandidates = true
      this.$store.commit('TEAMSCOREBOARD/SET_CURRENT_PAGE', 0)
      await this.$store.dispatch('TEAMSCOREBOARD/getCandidates', this.campaignId)
      await this.$store.dispatch('TEAMSCOREBOARD/getCandidatesTestIssues', this.campaignId)
      this.gettingCandidates = false
      this.mapIssuesAndUsers()
    },
    async nextPage (page) {
      this.$store.commit('TEAMSCOREBOARD/SET_CURRENT_PAGE', page)
      await this.$store.dispatch('TEAMSCOREBOARD/getCandidates', this.campaignId)
      await this.$store.dispatch('TEAMSCOREBOARD/getCandidatesTestIssues', this.campaignId)
      this.mapIssuesAndUsers()
    },
    mapIssuesAndUsers () {
      for (let index = 0; index < this.candidates.length; index++) {
        const filterIssue = this.issues.filter(x => x.user_id === this.candidates[index].user_id)
        if (filterIssue.length > 0) {
          this.candidates[index].testIssue = filterIssue
        } else {
          this.candidates[index].testIssue = null
        }
      }
    },
    async downloadTeamReport () {
      const teamName = this.$store.state.TEAMANALYTICS.currentTeam ? this.$store.state.TEAMANALYTICS.currentTeam.name : ''
      console.log('downloading...')
      const url = await this.$api.getFullTeamReportDownloadUrl(this.campaignId)
      try {
        const { data, headers } = await axios.get(url, {
          responseType: 'blob'
        })
        const contentType = headers['content-type']
        download(data, `Full Team Report ${teamName}.pdf`, contentType)
      } catch (error) {
        console.log(error)
      }
    },
    redirectToTeamReport () {
      this.$router.push({
        name: 'TeamReportAuto',
        params: { campaignId: this.campaignId }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scoreboard.scss';
</style>
